import {
  SelectionState,
  SelectionActionTypes,
  SUBJECT_SELECTED,
  TOPIC_SELECTED,
  SUBTOPIC_SELECTED,
  EXERCISE_SELECTED,
  BEGIN_LOADING,
  END_LOADING,
} from './types';

const initialState: SelectionState = {
  loading: true,
  subject: null,
  topic: null,
  subtopic: null,
  exercise: null,
};

export function selectionReducer(
  state = initialState,
  action: SelectionActionTypes
): SelectionState {
  switch (action.type) {
    case SUBJECT_SELECTED:
      return {
        ...state,
        subject: action.payload.subject,
        topic: null,
        subtopic: null,
        exercise: null,
      };
    case TOPIC_SELECTED:
      return {
        ...state,
        topic: action.payload.topic,
        subtopic: null,
        exercise: null,
      };
    case SUBTOPIC_SELECTED:
      return {
        ...state,
        subtopic: action.payload.subtopic,
        exercise: null,
      };
    case EXERCISE_SELECTED:
      return {
        ...state,
        exercise: action.payload.exercise,
      };
    case BEGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case END_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
