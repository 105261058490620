import { SubjectMeta } from '@mestr/firebase';
import { SET_SUBJECT_META, UserStatsAction } from './types';

export function setSubjectMeta(
  subjectMeta: SubjectMeta | null
): UserStatsAction {
  return {
    type: SET_SUBJECT_META,
    payload: { subjectMeta },
  };
}
