import { History } from 'history';

export function removeFromPath(toRemove: string, history: History): string {
  const currentPath = history.location.pathname
    .split('/')
    .filter((it) => it !== '');

  if (currentPath[currentPath.length - 1] === toRemove) {
    currentPath.pop();
  }
  return `/${currentPath.join('/')}`;
}

export default removeFromPath;
