import React, { useEffect, useRef } from 'react'
import styled from 'styled-components';

interface Props {
  active: boolean;
}

const Container = styled.div<Props>`
  border: ${({ theme, active }): string => active ? `1px solid ${theme.admin.highlight}` : 'none'};
  margin-bottom: 1em;
`;


const CellContainer: React.FC<Props> = ({ active, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (active && ref.current?.scrollIntoView) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [active])

  return (
    <Container ref={ref} active={active}>
      {children}
    </Container>
  )
}

export default CellContainer
