import { History } from 'history';

/**
 * Returns the current path with toAppend appended at the end – if it isn't already there.
 * @param toAppend Don't iclude '/'
 * @param history
 */
export function appendToPath(toAppend: string, history: History): string {
  const currentPath = history.location.pathname
    .split('/')
    .filter((it) => it !== '');

  if (currentPath[currentPath.length - 1] !== toAppend) {
    currentPath.push(toAppend);
  }

  return `/${currentPath.join('/')}`;
}

export default appendToPath;
