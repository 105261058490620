import { AuthState, AuthActionTypes, LOGGED_IN, LOGGED_OUT } from './types';

const initialState: AuthState = {
  user: null,
  initializing: true,
  error: null,
};

export function authReducer(
  state = initialState,
  action: AuthActionTypes
): AuthState {
  switch (action.type) {
    case LOGGED_IN:
      return {
        ...state,
        ...action.payload,
        initializing: false,
        error: null,
      };
    case LOGGED_OUT:
      return {
        ...state,
        user: null,
        initializing: false,
        error: null,
      };
    default:
      return state;
  }
}
