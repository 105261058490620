import styled from 'styled-components';
import { MestrIcon } from '@mestr/shared/assets';

export const AuthComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;

  padding: 1em;
  border-radius: 3px;

  background-color: ${(props) => props.theme.admin.dark};
  box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.55);
`;

export const Icon = styled(MestrIcon)`
  align-self: center;
  height: 60px;
  scale: 1.5;
  margin-bottom: 10px;
`;
