import React from 'react'
import { ResponsiveVideoContainer } from './YoutubeEmbed.styled'

interface Props {
  videoId?: string;
}

const YoutubeEmbed: React.FC<Props> = ({ videoId }) => {
  if (!videoId) return null;
  return (
    <ResponsiveVideoContainer>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube-nocookie.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </ResponsiveVideoContainer>
  )
}

export default YoutubeEmbed
