import { useState, useEffect } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore } from 'firebase/app';
import { getCustomClaims } from './auth';
import { firestoreRef } from './firebase';
import {
  DocumentData,
  Subject,
  Topic,
  Subtopic,
  Exercise,
  ExerciseQueueItem,
  SubjectMeta,
  CelledDocument,
} from './types';

function notNullOrUndefined(...args: (unknown | undefined | null)[]): boolean {
  for (let i = 0; i < args.length; i++) {
    if (args[i] === null || args[i] === undefined) {
      return false;
    }
  }
  return true;
}

export const useSubjects = (): [Subject[], boolean, Error?] => {
  const query = firestoreRef.collection('subjects');
  const [subjects, loading, error] = useCollectionData<Subject>(query, {
    idField: 'id',
  });
  return [subjects ?? [], loading, error];
};

export const useProtectedSubjects = (): [Subject[], boolean, Error?] => {
  const [query, setQuery] = useState<firestore.Query | undefined>();
  const [subjects, loading, error] = useCollectionData<Subject>(query, {
    idField: 'id',
  });
  const [pendingPermission, setPendingPermission] = useState(true);

  useEffect(() => {
    getCustomClaims()?.then((customClaims) => {
      if (!customClaims) return;
      setPendingPermission(false);
      if (customClaims.role === 'admin') {
        setQuery(firestoreRef.collection('subjects'));
      } else if (customClaims.role === 'moderator') {
        setQuery(
          firestoreRef
            .collection('subjects')
            .where(
              firestore.FieldPath.documentId(),
              'in',
              customClaims.subjects
            )
        );
      }
    });
  }, []);
  return [subjects ?? [], pendingPermission || loading, error];
};

export const useTopics = (
  subjectId?: string | null
): [Topic[], boolean, Error?] => {
  const query = notNullOrUndefined(subjectId)
    ? firestoreRef.collection(`subjects/${subjectId}/topics`).orderBy('index')
    : null;
  const [topics, loading, error] = useCollectionData<Topic>(query, {
    idField: 'id',
  });
  return [topics ?? [], loading, error];
};

export const useSubtopics = (
  subjectId?: string,
  topicId?: string
): [Subtopic[], boolean, Error?] => {
  const query = notNullOrUndefined(subjectId, topicId)
    ? firestoreRef
        .collection(`subjects/${subjectId}/topics/${topicId}/subtopics`)
        .orderBy('index')
    : null;

  const [subtopics, loading, error] = useCollectionData<Subtopic>(query, {
    idField: 'id',
  });
  return [subtopics ?? [], loading, error];
};

export const useExercises = (
  subjectId?: string,
  topicId?: string,
  subtopicId?: string
): [Exercise[], boolean, Error?] => {
  const query = notNullOrUndefined(subjectId, topicId)
    ? firestoreRef
        .collection(
          `subjects/${subjectId}/topics/${topicId}/subtopics/${subtopicId}/exercises`
        )
        .orderBy('index')
    : null;

  const [exercises, loading, error] = useCollectionData<Exercise>(query, {
    idField: 'id',
  });
  return [exercises ?? [], loading, error];
};

export async function fetchDocument(
  path: string | null
): Promise<DocumentData | null> {
  if (path === null) {
    return null;
  }

  const snapshot = await firestoreRef.doc(path).get();
  return snapshot.data() || null;
}

export async function fetchSubject(
  subjectId: string | null
): Promise<Subject | null> {
  const path = subjectId === null ? null : `subjects/${subjectId}`;
  const data = await fetchDocument(path);

  if (data !== null) {
    return {
      id: subjectId,
      name: data.name,
    } as Subject;
  }

  return null;
}

export async function fetchTopic(
  subjectId: string | null,
  topicId: string | null
): Promise<Topic | null> {
  let path: string | null = null;
  if (notNullOrUndefined(subjectId, topicId)) {
    path = `subjects/${subjectId}/topics/${topicId}`;
  }

  const data = await fetchDocument(path);
  if (data !== null) {
    return {
      id: topicId,
      name: data.name,
      index: data.index,
    } as Topic;
  }

  return null;
}

export async function fetchSubtopic(
  subjectId: string | null,
  topicId: string | null,
  subtopicId: string | null
): Promise<Subtopic | null> {
  let path: string | null = null;
  if (notNullOrUndefined(subjectId, topicId, subtopicId)) {
    path = `subjects/${subjectId}/topics/${topicId}/subtopics/${subtopicId}`;
  }

  const data = await fetchDocument(path);
  if (data !== null) {
    return {
      id: subtopicId,
      name: data.name,
      index: data.index,
      compendium: data.compendium,
    } as Subtopic;
  }

  return null;
}

export async function fetchExercise(
  subjectId: string | null,
  topicId: string | null,
  subtopicId: string | null,
  exerciseId: string | null
): Promise<Exercise | null> {
  if (
    subjectId === null ||
    topicId === null ||
    subtopicId === null ||
    exerciseId === null
  ) {
    return null;
  }

  const path = `subjects/${subjectId}/topics/${topicId}/subtopics/${subtopicId}/exercises/${exerciseId}`;
  const data = await fetchDocument(path);

  if (data !== null) {
    return {
      id: exerciseId,
      name: data.name,
      q: data.q,
      a: data.a,
      subjectId,
      topicId,
      subtopicId,
      index: data.index,
    };
  }

  return null;
}

function getIdsFromPath(
  path: string
): {
  subjectId: string | null;
  topicId: string | null;
  subtopicId: string | null;
  exerciseId: string | null;
} {
  const getId = (match: RegExpMatchArray | null): string | null =>
    match !== null ? match[0].split('/')[1] : null;

  const subjectMatch = path.match(/subjects\/[^/]+/);
  const topicMatch = path.match(/topics\/[^/]+/);
  const subtopicMatch = path.match(/subtopics\/[^/]+/);
  const exerciseMatch = path.match(/exercises\/[^/]+/);

  return {
    subjectId: getId(subjectMatch),
    topicId: getId(topicMatch),
    subtopicId: getId(subtopicMatch),
    exerciseId: getId(exerciseMatch),
  };
}

export async function fetchExerciseBatch(
  userId?: string,
  subjectId?: string,
  topicId?: string,
  subtopicId?: string,
  EXERCISE_BATCH_SIZE = 5
): Promise<Exercise[]> {
  if (!notNullOrUndefined(userId, subjectId)) {
    console.error(
      'ERROR: Attempted exercise batch fetch, but user is not logged in.'
    );
    return [];
  }

  /* Reference to user's exercise queue. */
  let exerciseQueueRef = firestoreRef
    .collection(`users/${userId}/enrolledSubjects/${subjectId}/exerciseQue`)
    /* Add filter to remove skipped exercises. */
    .orderBy('skippedUntil')
    .where('skippedUntil', '<', new Date());

  /* Filter according to given specificity. */
  if (topicId) {
    exerciseQueueRef = exerciseQueueRef.where('topicId', '==', topicId);
  }

  if (subtopicId) {
    exerciseQueueRef = exerciseQueueRef.where('subtopicId', '==', subtopicId);
  }

  /* Sort by due date. */
  exerciseQueueRef = exerciseQueueRef.orderBy('due').limit(EXERCISE_BATCH_SIZE);

  /* Get the exercise queue items (if any). */
  const exerciseQueueSnapshot = await exerciseQueueRef.get();
  if (exerciseQueueSnapshot.empty) {
    return [];
  }

  const exerciseQueueItems = exerciseQueueSnapshot.docs.map(
    (doc) => doc.data() as ExerciseQueueItem
  );

  /* Get refs and paths to the actual exercises. */
  const exerciseRefs = exerciseQueueItems.map((item) => item.ref);
  const exercisePaths = exerciseRefs.map((ref) => ref.path);

  /* Get the actual exercise batch. */
  const exerciseBatch = (
    await Promise.all(exerciseRefs.map((ref) => ref.get()))
  ).map((snapshot, index) => {
    const data = snapshot.data();
    const path = exercisePaths[index];
    const { subjectId, topicId, subtopicId, exerciseId } = getIdsFromPath(path);

    return {
      id: exerciseId,
      name: data?.name,
      q: data?.q,
      a: data?.a,
      subjectId,
      topicId,
      subtopicId,
    } as Exercise;
  });

  return exerciseBatch;
}

export async function fetchSubjectMeta(
  userId?: string | null,
  subjectId?: string | null
): Promise<SubjectMeta | null> {
  if (!userId || !subjectId) {
    throw new Error(
      `Can not fetch subject meta for userId ${userId} and subjectId ${subjectId}!`
    );
  }

  const subjectMetaRef = firestoreRef.doc(
    `users/${userId}/enrolledSubjects/${subjectId}`
  );

  const subjectMetaDocument = await subjectMetaRef.get();

  const subjectMetaData = subjectMetaDocument.data();

  if (subjectMetaData !== undefined) {
    if (subjectMetaData.init) return null;
    return subjectMetaData.subjectMeta as SubjectMeta;
  }

  subjectMetaRef.set({
    init: true,
  });

  return null;
}

export async function saveCompendium(
  compendiumDocument: CelledDocument,
  ref: string
): Promise<void> {
  await firestoreRef.doc(ref).update({ compendium: compendiumDocument });
}

export async function saveExercise(
  exerciseDocuments: { q: CelledDocument; a: CelledDocument },
  ref: string
): Promise<void> {
  const { q, a } = exerciseDocuments;
  await firestoreRef.doc(ref).update({ q, a });
}
