/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { CelledDocument } from '@mestr/firebase';
import { BlockMath, Markdown, ImageCell, Box } from './cells';
import YoutubeEmbed from './YoutubeEmbed';
import CellContainer from './CellContainer';

interface Props {
  document?: CelledDocument;
  activeCell?: string | null;
}

export const Preview: React.FC<Props> = ({ document, activeCell }) => {
  if (!document) return null;

  const { cells, cellOrder } = document;

  return (
    <>
      {cellOrder.map((id) => {
        const cell = cells[id];
        return (
          <CellContainer key={id} active={id === activeCell}>
            {(() => {
              switch (cell.type) {
                case 'text':
                  return <Markdown content={cell.value} />;
                case 'math':
                  return <BlockMath>{cell.value}</BlockMath>;
                case 'image':
                  return <ImageCell imageId={cell.value} />;
                case 'box':
                  return <Box content={cell.value} />;
                case 'video':
                  return <YoutubeEmbed videoId={cell.value} />
                default:
                  return <div>bad type</div>;
              }
            })()}
          </CellContainer>
        )
      })}
    </>
  );
};

export default Preview;
