import styled from 'styled-components';

export const BaseButton = styled.button.attrs<{
  type: 'button' | 'submit' | 'reset';
}>(({ type }) => ({
  type: type ?? 'button',
}))`
  /* Disable ugly OS-specific button styles */
  outline: none;
  border: none;
  cursor: pointer;

  border: 2px solid #0000; /* Keeps height constant when focusing */
  box-shadow: 3px 3px 9px -2px rgba(0, 0, 0, 0.35);
  transition: all 0.15s;

  &:disabled {
    background-color: grey;
  }

  &:hover {
    box-shadow: 3px 3px 20px -2px rgba(0, 0, 0, 0.35);
  }

  &:active {
    transform: scale(0.99);
    box-shadow: none;
  }

  &:focus {
    box-shadow: 3px 3px 20px -2px rgba(0, 0, 0, 0.35);
  }
`;

/** Icon button */
export const IconButton = styled(BaseButton)`
  background-color: inherit;

  width: 2.3em;
  height: 2.3em;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

/** Rounded solid button. Can be made smaller than default by setting optional prop `size` to `"small"`. */
export const RoundedButton = styled(BaseButton)<{
  size?: string;
  rounder?: boolean;
}>`
  width: 200px;
  border-radius: ${({ rounder }) => (rounder ? '30px' : '5px')};
  padding: 15px 30px;
  margin: 1em 0;

  color: black;
  font-weight: bold;
  font-size: ${(props): string => (props.size === 'small' ? '1rem' : '1.5rem')};
`;

/** RoundedButton with secondary theme color. */
export const SecondaryRoundedButton = styled(RoundedButton)`
  background-color: ${({ theme }) => theme.secondary[1]};
`;

export const TransparentRoundedButton = styled(RoundedButton)`
  background-color: transparent;
`;
