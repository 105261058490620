import React from 'react';
import { Helmet } from 'react-helmet'
import { Welcome } from './Welcome';
import { Subjects } from './Subjects';

export const Home: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Startside</title>
        <meta name="description" content="Velkommen til Mestr! Velg et fag du vil mestre og sett i gang." />
      </Helmet>
      <Welcome />
      <Subjects />
    </>
  );
};
