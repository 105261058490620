import { SubjectMeta } from '@mestr/firebase';

export interface UserStatsState {
  subjectMeta: SubjectMeta | null;
}

export const SET_SUBJECT_META = 'userStats/SET_SUBJECT_META';

export interface SetSubjectMetaAction {
  type: typeof SET_SUBJECT_META;
  payload: { subjectMeta: SubjectMeta | null };
}

export type UserStatsAction = SetSubjectMetaAction;
