import { User } from '@mestr/firebase';
import { DefaultRootState } from 'react-redux';
import { SelectionState } from '../selection';

export interface AuthState {
  user: User | null;
  initializing: boolean;
  error: string | null;
}

export interface ProjectRootState extends DefaultRootState {
  auth: AuthState;
  selection: SelectionState;
}

export const LOGGED_IN = 'auth/LOG_IN';
export const LOGGED_OUT = 'auth/LOG_OUT';

export interface LogInAction {
  type: typeof LOGGED_IN;
  payload: {
    user: User;
  };
}

export interface LogOutAction {
  type: typeof LOGGED_OUT;
}

export type AuthActionTypes = LogInAction | LogOutAction;
