/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState } from 'react';
import { EquationCellContainer, ResizeableEquation } from './equationCell.styled';
import debounce from './debounce';

const EquationWrapper: React.FC = ({ children }) => {
  const equationRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(1);
  const [parentWidth, setParentWidth] = useState(1)
  const [selected, setSelected] = useState(false);

  const handleResize = (): void => {
    if (equationRef.current && parentRef.current) {
      setWidth(equationRef.current.getBoundingClientRect().width)
      setParentWidth(parentRef.current.getBoundingClientRect().width)
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', debounce(handleResize));

    return (): void => {
      window.removeEventListener('resize', debounce(handleResize))
    }
  }, [])

  function handleClickOutside(event: MouseEvent): void {
    // @ts-ignore
    if (equationRef.current && !equationRef.current.contains(event.target)) {
      setSelected(false);
    }
    // Unbind the event listener on clean up
    document.removeEventListener("mousedown", handleClickOutside);
  }

  function handleClick(event: React.MouseEvent<HTMLDivElement>): void {
    setSelected(true);
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
  };


  return (
    <EquationCellContainer ref={parentRef}>
      <ResizeableEquation
        className={selected ? 'selected' : undefined}
        onClick={handleClick}
        ref={equationRef}
        width={width}
        parentWidth={parentWidth}
      >
        {children}
      </ResizeableEquation>
    </EquationCellContainer>
  )
}

export default EquationWrapper
