import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ProjectRootState } from '@mestr/reducers';
import { User } from '@mestr/firebase';


export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const [user, initializing] = useSelector<ProjectRootState, [User | null, boolean]>(
    ({ auth: { user, initializing } }) => [user, initializing]
  );

  if (initializing) {
    /* TODO: Finere loading-skjerm */
    return <p>Loading authstate...</p>;
  }

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
    );
  }

  return <Route {...props} />;
};
