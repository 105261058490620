import { authRef, firestoreRef } from './firebase';
import {
  logLoginWithEmail,
  logResetPassword,
  logSignOut,
  logSignUpWithEmail,
  logVerifyEmail,
} from './analytics';
import { CustomClaims, UserCredential } from './types';

export function doSignInWithEmailAndPassword(
  email: string,
  password: string
): Promise<UserCredential> {
  logLoginWithEmail();
  return authRef.signInWithEmailAndPassword(email, password);
}

export function doSignOut(): Promise<void> {
  logSignOut();
  return authRef.signOut();
}

/**
 * User data is stored two places: Firebase Auth and Firestore. This function
 * creates a user both places. If it fails at any point, return a rejected
 * promise with an error message.
 * @param email
 * @param password
 * @param displayName
 */
export async function doCreateUserWithEmailAndPassword(
  email: string,
  password: string,
  displayName: string
): Promise<void | never> {
  try {
    const userCredential = await authRef.createUserWithEmailAndPassword(
      email,
      password
    );

    const user = userCredential.user;
    if (user === null) {
      return Promise.reject('Could not create user.');
    }

    // Set display name on Firebase Auth user profile
    await user.updateProfile({ displayName });

    // Create user in Firestore
    await firestoreRef
      .collection('users')
      .doc(user.uid)
      .set({ email, displayName, role: 'USER' });

    logSignUpWithEmail();
  } catch (error) {
    console.log('CREATE USER CAUGHT ERROR', error);
    return Promise.reject(error.message);
  }
}

export function doSendEmailVerification(pathname: string): Promise<void> {
  const redirectUrl = `http://mestr.no${pathname.slice(
    0,
    pathname.indexOf('/verify')
  )}`;
  if (authRef.currentUser) {
    logVerifyEmail();
    return authRef.currentUser.sendEmailVerification({ url: redirectUrl });
  } else {
    return Promise.reject('Could not find currentUser');
  }
}

export function doResetPassword(email: string): Promise<void> {
  logResetPassword();
  return authRef.sendPasswordResetEmail(email);
}

export function doSetNewDisplayName(newDisplayName: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const { currentUser } = authRef;
    if (currentUser) {
      const promise1 = currentUser.updateProfile({
        displayName: newDisplayName,
      });
      const promise2 = firestoreRef
        .collection('users')
        .doc(currentUser.uid)
        .update({ displayName: newDisplayName });
      Promise.all([promise1, promise2])
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      reject('Something went wrong!');
    }
  });
}

export async function getCustomClaims(): Promise<CustomClaims | undefined> {
  if (!authRef.currentUser) {
    return;
  }
  const tokenResult = await authRef.currentUser.getIdTokenResult();
  return tokenResult.claims;
}
