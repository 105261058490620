import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthModal, useAuthStateListener } from '@mestr/auth';
import RootWrapper from './App.styled';
import MenuRouter from '../pages/MenuRouter';
const Exercise = lazy(() => import('../pages/Exercise'));

export const App: React.FC = () => {
  useAuthStateListener();

  return (
    <RootWrapper>
      <Suspense fallback={<RootWrapper />}>
        <Route
          path={['*/login', '*/signup', '*/verify', '*/reset-password']}
          component={AuthModal}
        />
        <Switch>
          <Route
            path={[
              '/subject/:subjectId/exercise/',
              '/subject/:subjectId/topic/:topicId/exercise/',
              '/subject/:subjectId/topic/:topicId/subtopic/:subtopicId/exercise/:exerciseId?',
            ]}
            component={Exercise}
          />
          <Route>
            <MenuRouter />
          </Route>
        </Switch>
      </Suspense>
    </RootWrapper>
  );
};
