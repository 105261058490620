// Denne funksjonen er for å forhindre at det fyres resizefunksjon for rerender.
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
// eslint-disable-next-line @typescript-eslint/ban-types
function debounce(fn: Function, ms = 300) {
  // const time = time || 100; // 100 by default if no param
  let timeoutId: ReturnType<typeof setTimeout>;
  // eslint-disable-next-line
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
}

export default debounce;
