import React from 'react';
import { SubjectList } from './SubjectList';
import { Description, SubjectListWrapper, SubjectsWrapper } from './Subjects.styled';

export const Subjects: React.FC = () => {
  return (
    <SubjectsWrapper id="subjects">
      <Description>
        <div>
          <h1>Velg et fag</h1>
          <p>
            Fagene er gratis og utviklet av flinke studenter som ønsker at du
            skal lykkes!
          </p>
        </div>
      </Description>
      <SubjectListWrapper>
        <SubjectList />
      </SubjectListWrapper>
    </SubjectsWrapper>
  );
};
