import styled from 'styled-components';

export const FooterWrapper = styled.section`
  height: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10%;
  color: ${({ theme }) => theme.mestrBlack};
  background-color: ${({ theme }) => theme.primary[2]};
`;
