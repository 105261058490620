import { DefaultTheme } from 'styled-components';

export interface AccentColor {
  primary: string;
  light: string;
}

declare module 'styled-components' {
  export interface DefaultTheme {
    fonts: {
      [key: string]: string;
    };
    neutral: string;
    white: string;
    mestrBlack: string;
    adminWhite: string;
    primary: {
      [key: number]: string;
    };
    secondary: {
      [key: number]: string;
    };
    admin: {
      primary: string;
      light: string;
      dark: string;
      highlight: string;
    };
    secondaryLight: string;
    accentColors: {
      [key: string]: AccentColor;
    };
    randomAccentColor: () => AccentColor;
    accentColorByIndex: (index: number) => AccentColor;
  }
}

const theme: DefaultTheme = {
  // FONTS
  fonts: {
    gothamMedium: '"Gotham-Medium", sans-serif',
    gothamBook: '"Gotham-Book", sans-serif',
  },

  // TEXT COLORS
  white: '#FFFFFF',
  mestrBlack: '#01040C',
  adminWhite: '#F7F8FA',

  neutral: '#EDEDED',

  // MESTR PALETTE
  primary: {
    1: '#BBE0EC',
    2: '#9ACCDC',
    3: '#7EBACC',
    4: '#6FADC0',
    5: '#5E9BAE',
    6: '#357285',
  },
  secondary: {
    1: '#E96B07',
    2: '#FFCA5C',
  },
  secondaryLight: '#FFE989',

  // ADMIN PALETTE
  admin: {
    primary: '#25282F',
    light: '#2A303A',
    dark: '#212329',
    highlight: '#E4943D',
  },

  // ACCENT COLORS
  accentColors: {
    mint: {
      primary: '#8ABAA6',
      light: '#8ABAA6',
    },

    // IKKE I BRUK?
    red: {
      primary: '#CB735B',
      light: '#E68165',
    },
    yellow: {
      primary: '#EDCA87',
      light: '#FBD58E',
    },
    green: {
      primary: '#518E86',
      light: '#5BA399',
    },
    blue: {
      primary: '#438A9C',
      light: '#4C9FB5',
    },
    gray: {
      primary: '#929CA3',
      light: '#A1ADB5',
    },
    cyan: {
      primary: '#70BBB1',
      light: '#78CEC3',
    },
    white: {
      primary: '#E2E3E5',
      light: '#E8E9EC',
    },
    orange: {
      primary: '#DB965A',
      light: '#EBA160',
    },
  },

  randomAccentColor(): AccentColor {
    const keys = Object.keys(this.accentColors);
    const randomKey = keys[Math.floor(Math.random() * keys.length)];
    return this.accentColors[randomKey];
  },

  accentColorByIndex(index: number): AccentColor {
    const keys = Object.keys(this.accentColors);
    return this.accentColors[keys[index % keys.length]];
  },
};

export default theme;
