import { Subject, Topic, Subtopic, Exercise } from '@mestr/firebase';

export interface SelectionState {
  loading: boolean;
  subject: Subject | null;
  topic: Topic | null;
  subtopic: Subtopic | null;
  exercise: Exercise | null;
}

export const SUBJECT_SELECTED = 'selection/SUBJECT_SELECTED';
export const TOPIC_SELECTED = 'selection/TOPIC_SELECTED';
export const SUBTOPIC_SELECTED = 'selection/SUBTOPIC_SELECTED';
export const EXERCISE_SELECTED = 'selection/EXERCISE_SELECTED';
export const BEGIN_LOADING = 'selection/BEGIN_LOADING';
export const END_LOADING = 'selection/END_LOADING';

export interface SelectSubjectAction {
  type: typeof SUBJECT_SELECTED;
  payload: {
    subject: Subject | null;
  };
}

export interface SelectTopicAction {
  type: typeof TOPIC_SELECTED;
  payload: {
    topic: Topic | null;
  };
}
export interface SelectSubtopicAction {
  type: typeof SUBTOPIC_SELECTED;
  payload: {
    subtopic: Subtopic | null;
  };
}

export interface SelectExerciseAction {
  type: typeof EXERCISE_SELECTED;
  payload: {
    exercise: Exercise | null;
  };
}

export interface BeginLoadingAction {
  type: typeof BEGIN_LOADING;
}

export interface EndLoadingAction {
  type: typeof END_LOADING;
}

export type SelectionActionTypes =
  | SelectSubjectAction
  | SelectTopicAction
  | SelectSubtopicAction
  | SelectExerciseAction
  | BeginLoadingAction
  | EndLoadingAction;
