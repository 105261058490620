import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { doSignOut, User } from '@mestr/firebase';
import { ProjectRootState } from '@mestr/reducers';
import { useDeviceIsDesktop } from '../../../utils/useDeviceIsDesktop';
import { UserSection } from '../Navbar.styled';
import { ProfileButton } from '../NavButtons';
import { ProfileIcon, SignOutIcon } from '../NavButtons.styled';
import { Anchor, Menu } from './UserComponent.styled';
import { useHistory } from 'react-router-dom';
import { appendToPath } from '@mestr/route-utils';

const UserMenu: React.FC<{ user: User | null, closeMenu: () => void }> = ({ user, closeMenu }) => {
  const history = useHistory();

  if (user) {
    return (
      <Anchor>
        <Menu onClick={closeMenu}>
          <li onClick={(): void => history.push('/user')}>
            <ProfileIcon />
            <p>Administrer konto</p>
          </li>
          <li onClick={doSignOut}>
            <SignOutIcon />
            <p>Logg ut</p>
          </li>
        </Menu>
      </Anchor>
    )
  }
  return null;
}


const UserComponent: React.FC = () => {
  const history = useHistory();
  const isDesktop = useDeviceIsDesktop();
  const user = useSelector<ProjectRootState, User | null>((state) => state.auth.user);
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);

  function handleClick(): void {
    if (!user) {
      history.push(appendToPath('login', history));
    } else {
      setShowUserMenu(!showUserMenu);
    }
  }

  return (
    <UserSection>
      {isDesktop && user && <p>{user.displayName || user?.email}</p>}
      <ProfileButton onClick={handleClick} />
      {showUserMenu && <UserMenu user={user} closeMenu={(): void => setShowUserMenu(false)} />}
    </UserSection>
  )

}

export default UserComponent
