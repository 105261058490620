import React, { useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { doSignInWithEmailAndPassword } from '@mestr/firebase';

import { SecondaryRoundedButton } from '@mestr/components';
import { Form, Input, Label } from '../AuthComponent.styled';

const LoginForm: React.FC = () => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    doSignInWithEmailAndPassword(email, password)
      .then(() =>
        history.replace(
          history.location.pathname.slice(
            0,
            history.location.pathname.indexOf('/login')
          )
        )
      )
      .catch((error: Error) => {
        setError(error.message);
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Label htmlFor="email">E-post</Label>
      <Input
        name="email"
        type="email"
        placeholder="Skriv inn din e-post"
        value={email}
        onChange={(e): void => setEmail(e.target.value)}
      />
      <Label htmlFor="password">Passord</Label>
      <Input
        name="password"
        type="password"
        placeholder="Skriv inn ditt passord"
        value={password}
        onChange={(e): void => setPassword(e.target.value)}
      />
      <p style={{ color: 'red' }}>{error}</p>
      <SecondaryRoundedButton
        size="small"
        type="submit"
        style={{ alignSelf: 'center' }}
      >
        Logg inn
      </SecondaryRoundedButton>
    </Form>
  );
};

export default LoginForm;
