import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from "react-helmet";

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { ThemeProvider } from 'styled-components';
import theme from '@mestr/theme';
import '@mestr/shared/styles';

import { App } from './app';
import { configureStore } from './store';
import { SkeletonTheme } from 'react-loading-skeleton';

const history = createBrowserHistory();
const store = configureStore(history);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <SkeletonTheme
            color={theme.primary[2]}
            highlightColor={theme.primary[3]}
          >
            <Helmet>
              <title>Mestr</title>
              <meta name="description" content="Få full oversikt over fagene dine med skreddersydde kompendier og oppgaver for studenter." />
            </Helmet>
            <App />
          </SkeletonTheme>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
