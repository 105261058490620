import { useState, useEffect } from 'react';

export const useDeviceIsDesktop = (): boolean => {
  const minScreenWidth = 768;
  const [deviceIsDesktop, setDeviceIsDesktop] = useState(false);

  function checkWindowSize(): void {
    setDeviceIsDesktop(window.innerWidth >= minScreenWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', checkWindowSize);
    checkWindowSize();
    return (): void => {
      window.removeEventListener('resize', checkWindowSize);
    };
  }, []);

  return deviceIsDesktop;
};
