import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { User, doSignOut } from '@mestr/firebase';
import { appendToPath } from '@mestr/route-utils';
import { ProjectRootState } from '@mestr/reducers';
import {
  StyledNav as MestrNav,
  StyledAdminNav as AdminNav,
  StyledLink,
  Logo,
  UserSection,
  IconButton,
  ProfileIcon,
  SignOutButton,
  SignOutIcon,
} from './navbar.styled';

const NavbarContent: React.FC<{
  lightText?: boolean;
}> = ({ lightText }) => {
  const user = useSelector<ProjectRootState, User | null>((state) => state.auth.user);
  const history = useHistory();

  return (
    <>
      <StyledLink to="/">
        <Logo light={lightText} />
      </StyledLink>
      <UserSection>
        {user ? (
          <>
            <p>Logged in as {user.displayName || user.email}</p>
            <SignOutButton onClick={doSignOut} type="button">
              <SignOutIcon light={lightText} />
            </SignOutButton>
          </>
        ) : (
            <StyledLink to={appendToPath('login', history)}>
              <p>Logg inn</p>
              <IconButton type="button">
                <ProfileIcon light={lightText} />
              </IconButton>
            </StyledLink>
          )}
      </UserSection>
    </>
  );
};

export const MestrNavbar: React.FC<{ solid?: boolean }> = ({ solid }) => (
  <MestrNav solid={solid}>
    <NavbarContent />
  </MestrNav>
);

export const AdminNavbar: React.FC = () => (
  <AdminNav light>
    <NavbarContent lightText />
  </AdminNav>
);
