import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  color: ${({ theme }) => theme.mestrBlack};
  font-size: 1.4em;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    opacity: 0.5;
    transition: opacity 50ms ease-in-out;
  }
`;

export const Logo = styled.img.attrs({
  src: '/assets/images/logo.png',
  alt: 'Mestr logo',
})`
  max-height: 30px;

  @media only screen and (min-width: 768px) {
    max-height: 40px;
  }
`;

export const BackButtonIcon = styled(FontAwesomeIcon).attrs({
  icon: faAngleLeft,
})`
  font-size: 1.1em;
`;

export const ProfileIcon = styled(FontAwesomeIcon).attrs({
  icon: faUser,
})`
  font-size: 1.1em;
`;

export const SignOutIcon = styled(FontAwesomeIcon).attrs({
  icon: faSignOutAlt,
})`
  font-size: 1.1em;
`;
