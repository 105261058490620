import { UserStatsState, UserStatsAction, SET_SUBJECT_META } from './types';

const initialState: UserStatsState = {
  subjectMeta: null,
};

export function userStatsReducer(
  state = initialState,
  action: UserStatsAction
): UserStatsState {
  switch (action.type) {
    case SET_SUBJECT_META:
      return {
        ...state,
        subjectMeta: action.payload.subjectMeta,
      };
    default:
      return state;
  }
}
