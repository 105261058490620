import { Middleware } from 'redux';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import {
  fetchSubject,
  fetchTopic,
  fetchSubtopic,
  fetchExercise,
} from '@mestr/firebase';

import {
  beginLoading,
  endLoading,
  selectSubject,
  SelectionState,
  selectTopic,
  selectSubtopic,
  selectExercise,
} from '@mestr/reducers';

interface ParamTypes {
  subjectId: string | null;
  topicId: string | null;
  subtopicId: string | null;
  exerciseId: string | null;
}

const getParamsFromPath = (pathname: string): ParamTypes => {
  /**
   * @param match A string on the form "/(subject|topic|subtopic|exercise)\/ID"
   */
  function getIdFromRegExpMatchArray(
    match: RegExpMatchArray | null
  ): string | null {
    if (match !== null) {
      const splitMatch = match[0].split('/').filter((it) => it !== '');
      return splitMatch[splitMatch.length - 1];
    }

    return null;
  }

  const subjectMatch = pathname.match(/\/subject\/[^/]+/g);
  const topicMatch = pathname.match(/\/topic\/[^/]+/g);
  const subtopicMatch = pathname.match(/\/subtopic\/[^/]+/g);
  const exerciseMatch = pathname.match(/\/exercise\/[^/]+/g);

  return {
    subjectId: getIdFromRegExpMatchArray(subjectMatch),
    topicId: getIdFromRegExpMatchArray(topicMatch),
    subtopicId: getIdFromRegExpMatchArray(subtopicMatch),
    exerciseId: getIdFromRegExpMatchArray(exerciseMatch),
  };
};

export const locationMiddleware: Middleware = (store) => (next) => async (
  action
): Promise<void> => {
  if (action.type === LOCATION_CHANGE) {
    const locationChangeAction = action as LocationChangeAction;
    const pathname = locationChangeAction.payload.location.pathname;
    const params = getParamsFromPath(pathname);
    const selectionState: SelectionState = store.getState().selection;

    if (params.subjectId !== selectionState.subject?.id) {
      store.dispatch(beginLoading());

      const subjectPromise = fetchSubject(params.subjectId);

      store.dispatch(selectSubject(await subjectPromise));
      store.dispatch(endLoading());
    }

    if (params.topicId !== selectionState.topic?.id) {
      store.dispatch(beginLoading());

      const topic = await fetchTopic(params.subjectId, params.topicId);

      store.dispatch(selectTopic(topic));
      store.dispatch(endLoading());
    }

    if (params.subtopicId !== selectionState.subtopic?.id) {
      store.dispatch(beginLoading());

      const subtopic = await fetchSubtopic(
        params.subjectId,
        params.topicId,
        params.subtopicId
      );

      store.dispatch(selectSubtopic(subtopic));
      store.dispatch(endLoading());
    }

    if (params.exerciseId !== selectionState.exercise?.id) {
      store.dispatch(beginLoading());

      const exercise = await fetchExercise(
        params.subjectId,
        params.topicId,
        params.subtopicId,
        params.exerciseId
      );

      store.dispatch(selectExercise(exercise));
      store.dispatch(endLoading());
    }
  }

  next(action);
};
