import { firestoreRef } from './firebase';

export type CollectionIds = {
  subjectId: string;
  topicId?: string;
  subtopicId?: string;
};

export function getCollectionRef({
  subjectId,
  topicId,
  subtopicId,
}: CollectionIds): firebase.firestore.CollectionReference {
  if (subtopicId && topicId) {
    return firestoreRef
      .collection('subjects')
      .doc(subjectId)
      .collection('topics')
      .doc(topicId)
      .collection('subtopics')
      .doc(subtopicId)
      .collection('exercises');
  }
  if (topicId) {
    return firestoreRef
      .collection('subjects')
      .doc(subjectId)
      .collection('topics')
      .doc(topicId)
      .collection('subtopics');
  }
  if (subjectId) {
    return firestoreRef
      .collection('subjects')
      .doc(subjectId)
      .collection('topics');
  }
  return firestoreRef.collection('subjects');
}

// export const dbRefDocument = (
//   subjectId,
//   topicId = false,
//   subtopicId = false,
//   exerciseId = false
// ) => {
//   if (exerciseId) {
//     return dbRef
//       .collection('subjects')
//       .doc(subjectId)
//       .collection('topics')
//       .doc(topicId)
//       .collection('subtopics')
//       .doc(subtopicId)
//       .collection('exercises')
//       .doc(exerciseId);
//   }
//   if (subtopicId) {
//     return dbRef
//       .collection('subjects')
//       .doc(subjectId)
//       .collection('topics')
//       .doc(topicId)
//       .collection('subtopics')
//       .doc(subtopicId);
//   }
//   if (topicId) {
//     return dbRef
//       .collection('subjects')
//       .doc(subjectId)
//       .collection('topics')
//       .doc(topicId);
//   }
//   return dbRef.collection('subjects').doc(subjectId);
// };
