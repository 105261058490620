import styled from 'styled-components';

export const NavbarWrapper = styled.nav<{
  solid?: boolean;
  light?: boolean;
}>`
  display: grid;
  grid-template-areas: 'l c r';
  grid-template-columns: 1fr 1fr 1fr;

  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  max-width: 100vw;
  z-index: 1;

  height: 55px;
  position: fixed;
  padding: 0em 2em;
  background-color: inherit;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);

  @media only screen and (min-width: 768px) {
    height: 80px;
    position: relative;
    padding: 0em 4em;
    background-color: ${({ theme, solid }) =>
    solid ? theme.white : 'inherit'};
    box-shadow: ${({ solid }) =>
    solid ? '0px 0px 10px rgba(0, 0, 0, 0.18)' : 'none'};
  }


  color: ${({ theme }) => (theme.mestrBlack)};
  font-family: 'Gotham-Book', sans-serif;
`;

export const BackButtonWrapper = styled.div`
  grid-area: l;
  justify-self: left;

  @media only screen and (min-width: 768px) {
    position: absolute;
    top: calc(80px + 3em); // Height of navbar + 3em
    left: 4em;
  }
`;

export const LogoButtonWrapper = styled.div`
  grid-area: c;
  justify-self: center;

  @media only screen and (min-width: 768px) {
    grid-area: l;
    justify-self: left;
  }

  display: flex;
  align-items: center;
  text-decoration: none;
`;


export const UserSection = styled.div`
  grid-area: r;
  justify-self: right;

  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 1em;
  }
`;
