import React, { useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { doCreateUserWithEmailAndPassword } from '@mestr/firebase';

import { SecondaryRoundedButton } from '@mestr/components';
import { Form, Label, Input } from '../AuthComponent.styled';

const SignupForm: React.FC = () => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (repeatPassword !== password) {
      setError('The passwords are not equal');
      return;
    }

    doCreateUserWithEmailAndPassword(email, password, displayName)
      .then(() => history.replace('verify'))
      .catch((error) => setError(error));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Label htmlFor="displayName">Brukernavn</Label>
      <Input
        name="displayName"
        placeholder="Velg et brukernavn"
        value={displayName}
        onChange={(e): void => setDisplayName(e.target.value)}
      />
      <Label htmlFor="email">E-post</Label>
      <Input
        name="email"
        type="email"
        placeholder="Skriv inn din e-post"
        value={email}
        onChange={(e): void => setEmail(e.target.value)}
      />
      <Label htmlFor="password">Passord</Label>
      <Input
        name="password"
        type="password"
        placeholder="Velg et sikkert passord"
        value={password}
        onChange={(e): void => setPassword(e.target.value)}
        autoComplete="new-password"
      />
      <Label htmlFor="password">Gjenta passord</Label>
      <Input
        name="password"
        type="password"
        placeholder="Gjenta passordet"
        value={repeatPassword}
        onChange={(e): void => setRepeatPassword(e.target.value)}
        autoComplete="new-password"
      />
      <p style={{ color: 'red' }}>{error}</p>
      <SecondaryRoundedButton size="small" type="submit">
        Registrer bruker
      </SecondaryRoundedButton>
    </Form>
  );
};

export default SignupForm;
