import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Modal } from '@mestr/components';
import AuthComponent from '../auth-component/AuthComponent';
import { AuthComponentContainer, Icon } from './AuthModal.styled';

/* eslint-disable-next-line */
export interface AuthModalProps extends RouteComponentProps {}

export const AuthModal = (props: AuthModalProps): JSX.Element => {
  return (
    <Modal onClose={props.history.goBack}>
      <AuthComponentContainer>
        <Icon />
        <AuthComponent />
      </AuthComponentContainer>
    </Modal>
  );
};

export default AuthModal;
