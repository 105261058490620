import styled from 'styled-components';
import { Cell } from '../previewCells.styled';

export const EquationCellContainer = styled(Cell)`
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const ResizeableEquation = styled.div<{
  width: number;
  parentWidth: number;
}>`
  transform: ${({ parentWidth, width }) =>
    `scale(min(1, ${parentWidth / width}))`};
  transition: transform 200ms;

  &.selected {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    line-height: 2em;
    transform: scale(1);
  }
`;
