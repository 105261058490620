import React from 'react';
import { IconButton } from '@mestr/components';
import { StyledLink, Logo, BackButtonIcon, ProfileIcon, SignOutIcon } from './NavButtons.styled';

export const LogoButton: React.FC = () => {
  return (
    <StyledLink aria-label="Gå til hjemmesiden" to={'/'}>
      <Logo />
    </StyledLink>
  );
};

export const BackButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <IconButton aria-label="Gå tilbake" onClick={onClick}>
      <BackButtonIcon />
    </IconButton>
  );
};

export const ProfileButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <IconButton aria-label="Logg inn eller administrer bruker" onClick={onClick}>
      <ProfileIcon />
    </IconButton>
  );
};

export const SignOutButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <IconButton aria-label="Logg ut" onClick={onClick}>
      <SignOutIcon />
    </IconButton>
  );
};
