import { useDispatch } from 'react-redux';
import { User, FirebaseUser, authRef } from '@mestr/firebase';
import { logInUser, logOutCurrentUser } from '@mestr/reducers';

/**
 * Bridge between Redux and Firebase auth session. Dispatches the appropriate
 * Redux auth action when Firebase auth state changes.
 */
export const useAuthStateListener = (): void => {
  const dispatch = useDispatch();

  authRef.onAuthStateChanged((firebaseUser: FirebaseUser | null) => {
    if (firebaseUser !== null) {
      const user: User = {
        uid: firebaseUser.uid,
        emailVerified: firebaseUser.emailVerified,
        displayName: firebaseUser.displayName,
        email: firebaseUser.email,
      };

      dispatch(logInUser(user));
    } else {
      dispatch(logOutCurrentUser());
    }
  });
};
