import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { User, doSendEmailVerification } from '@mestr/firebase';
import { ProjectRootState } from '@mestr/reducers';
import { SecondaryRoundedButton } from '@mestr/components';

const VerifyEmail: React.FC = () => {
  return (
    <>
      <h1>Bekreft e-postaddressen din</h1>
      <br />
      <Content />
    </>
  );
};

const Content = (): JSX.Element => {
  const authUser: User | null = useSelector(
    (state: ProjectRootState) => state.auth.user
  );
  const [emailSent, setEmailSent] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const history = useHistory();
  console.log(history);

  const handleConfirm = (): void => {
    console.log('sending email');
    doSendEmailVerification(history.location.pathname)
      .then(() => {
        setEmailSent(true);
      })
      .catch((e: Error) => {
        console.log(e);
        setError(e.message);
      });
  };

  React.useEffect(() => {
    if (authUser?.emailVerified) {
      history.replace(
        history.location.pathname.slice(
          0,
          history.location.pathname.indexOf('/verify')
        )
      );
    }
  }, [authUser, history]);

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!authUser) {
    return <p>Loading...</p>;
  }

  if (!emailSent) {
    return (
      <>
        <p>
          Før vi fortsetter må vi først bekrefte at{' '}
          <strong>{authUser.email}</strong> er din addresse. <br />
          Trykk <strong>Bekreft</strong> og vi sender deg en e-post med en link
          som gir bekreftelsen vi trenger.
        </p>
        <br />
        <SecondaryRoundedButton onClick={handleConfirm}>
          Bekreft
        </SecondaryRoundedButton>
      </>
    );
  }

  return (
    <p>
      En epost er sendt til <strong>{authUser.email}</strong> for å bekrefete
      din epost addresse. Trykk på linken i eposten for å fortsette. <br />
      PS: Vær oppmerksom på at eposten kan havne i søppelposten.
    </p>
  );
};

export default VerifyEmail;
