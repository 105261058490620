import styled from 'styled-components';

const ErrorUI = styled.div`
  color: red;
  border: 1px solid red;
  padding: 24px;
  margin-top: 25px;
  font-family: 'Open Sans', sans-serif;
`;

export default ErrorUI;
