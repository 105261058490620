import { Subject, Topic, Subtopic, Exercise } from '@mestr/firebase';
import {
  SUBJECT_SELECTED,
  TOPIC_SELECTED,
  SUBTOPIC_SELECTED,
  EXERCISE_SELECTED,
  BEGIN_LOADING,
  END_LOADING,
  SelectionActionTypes,
} from './types';

export function selectSubject(subject: Subject | null): SelectionActionTypes {
  return {
    type: SUBJECT_SELECTED,
    payload: { subject },
  };
}

export function selectTopic(topic: Topic | null): SelectionActionTypes {
  return {
    type: TOPIC_SELECTED,
    payload: { topic },
  };
}

export function selectSubtopic(
  subtopic: Subtopic | null
): SelectionActionTypes {
  return {
    type: SUBTOPIC_SELECTED,
    payload: { subtopic },
  };
}

export function selectExercise(
  exercise: Exercise | null
): SelectionActionTypes {
  return {
    type: EXERCISE_SELECTED,
    payload: { exercise },
  };
}

export function beginLoading(): SelectionActionTypes {
  return {
    type: BEGIN_LOADING,
  };
}

export function endLoading(): SelectionActionTypes {
  return {
    type: END_LOADING,
  };
}
