import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  NavbarWrapper,
  LogoButtonWrapper,
  BackButtonWrapper,
} from './Navbar.styled';
import { LogoButton, BackButton } from './NavButtons';
import { useDeviceIsDesktop } from '../../utils/useDeviceIsDesktop';
import UserComponent from './UserComponent';

interface NavbarProps extends RouteComponentProps {
  solid?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ solid, history }) => {
  const isDesktop = useDeviceIsDesktop();
  const [showGoBack, setShowGoBack] = useState<boolean>(false);

  useEffect(() => {
    if (isDesktop || history.location.pathname === '/') {
      setShowGoBack(false);
    } else {
      setShowGoBack(true);
    }
  }, [isDesktop, history.location.pathname])

  return (
    <NavbarWrapper solid={solid}>
      <LogoButtonWrapper>
        <LogoButton />
      </LogoButtonWrapper>
      <UserComponent />
      {showGoBack &&
        <BackButtonWrapper>
          <BackButton onClick={history.goBack} />
        </BackButtonWrapper>
      }
    </NavbarWrapper>
  );
};

export default withRouter(Navbar)