/* eslint-disable @typescript-eslint/ban-ts-comment */
/*
For some reason TS doesn't realize it's okay to pass arbitrary
strings (custom events) to logEvent. For some other reason
@ts-nocheck, which is supposed to ignore TS checking for the
entire file, doesn't work.

Workaround: @ts-ignore for every line which logs a custom event.
🤮🤮🤮
*/

import { analytics } from './firebase';

/* AUTH EVENTS */
export const logLoginWithEmail = (): void =>
  analytics.logEvent('login', { method: 'E-mail' });

// @ts-ignore
export const logSignOut = (): void => analytics.logEvent('sign_out');

export const logSignUpWithEmail = (): void =>
  analytics.logEvent('sign_up', { method: 'E-mail' });

export const logVerifyEmail = (): void =>
  // @ts-ignore
  analytics.logEvent('send_email_verification');

export const logResetPassword = (): void =>
  // @ts-ignore
  analytics.logEvent('send_password_reset_email');

/* MESTR EVENTS */
export const logExerciseStart = (): void =>
  // @ts-ignore
  analytics.logEvent('start_exercise_batch');

export const logExerciseFinish = (): void =>
  // @ts-ignore
  analytics.logEvent('finish_exercise_batch');
