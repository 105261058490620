import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyA99KH17c3_UJHlbrKqwye_YW2Xv1sDFiE',
  authDomain: 'mestr-83c59.firebaseapp.com',
  databaseURL: 'https://mestr-83c59.firebaseio.com',
  projectId: 'mestr-83c59',
  storageBucket: 'mestr-83c59.appspot.com',
  messagingSenderId: '403121467597',
  appId: '1:403121467597:web:e28ce88082f39acb',
  measurementId: 'G-QLT65MLC0G',
};

firebase.initializeApp(firebaseConfig);
if (
  window.location.hostname === 'localhost' &&
  process.env.NX_USE_EMULATOR === 'true'
) {
  firebase.firestore().settings({
    host: 'localhost:8080',
    ssl: false,
  });
  firebase.auth().useEmulator('http://localhost:9099');
}

/**
 * TRY NOT TO USE REFS DIRECTLY
 *
 * Accessing the methods available on these refs in the apps
 * is dangerous. They also have cumbersome return types. Instead,
 * create functions that wrap the methods and do one logical
 * operation. For instance, the doCreateUserWithEmailAndPassword
 * function in ./auth.ts uses both authRef and firestoreRef, but
 * does one logical operation, namely creating a user.
 *
 * Only use these directly if creating a function in this library
 * is for some reason not practical.
 */
export default firebase;
export const authRef = firebase.auth();
export const firestoreRef = firebase.firestore();
export const imageStorageRef = firebase.storage().ref().child('images');
export const timestamp = firebase.firestore.Timestamp;
export const analytics = (function ():
  | firebase.analytics.Analytics
  | { logEvent: (_: string) => void } {
  if (process.env.JEST_WORKER_ID === undefined) {
    return firebase.analytics();
  }
  return {
    logEvent: (_: string): void => undefined,
  };
})();

// Configure FirebaseUI.
export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: (): boolean => false,
  },
};
