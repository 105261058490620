import React from 'react';
import { scroller } from 'react-scroll';
import { WelcomeWrapper, Figure, WelcomeText } from './Welcome.styled';

export const Welcome: React.FC = () => {
  const scrollToSection = (): void => {
    scroller.scrollTo('subjects', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <WelcomeWrapper>
      <WelcomeText>
        <div>
          <h1>Mestre fagene dine</h1>
          <p>
            Få full oversikt over fagene dine med skreddersydde kompendier
            og oppgaver for studenter.
          </p>
          <button onClick={scrollToSection} type="button">
            Kom i gang
          </button>
        </div>
      </WelcomeText>
      <Figure />
    </WelcomeWrapper>
  );
};
