import { combineReducers, Reducer } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import {
  authReducer,
  selectionReducer,
  SelectionState,
  AuthState,
} from '@mestr/reducers';
import { userStatsReducer, UserStatsState } from './userStats';

export type RootState = {
  auth: AuthState;
  selection: SelectionState;
  router: RouterState;
  userStats: UserStatsState;
};

export const createRootReducer = (history: History): Reducer<RootState> =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    selection: selectionReducer,
    userStats: userStatsReducer,
  });
