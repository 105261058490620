import styled from 'styled-components';

export const DimmedBackground = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.625);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

export const StyledContainer = styled.aside<{
  width?: number;
  height?: number;
}>`
  width: ${(props) => `${props.width}%`};
  height: ${(props) => `${props.height}%`};
  max-height: 90%;
  max-width: 90%;
  background-color: ${(props) => props.theme.neutral};
  overflow-y: auto;

  box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.55);
  border-radius: 3px;

  // This makes scrollbar look nice on firefox
  scrollbar-width: thin;

  // If we want to set another color to firefox scrollbar
  // scrollbar-color: blue red;

  // Scrollbarstyling only works on chrome, opera and safari
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;
