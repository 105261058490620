import styled from 'styled-components';

const CenteringWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-y: auto;
  background: ${props => props.theme.neutral};
`;

export default CenteringWrapper;
