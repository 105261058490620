import { createStore, compose, applyMiddleware, Store } from 'redux';
import reduxThunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import { History } from 'history';
import { createRootReducer, RootState } from './createRootReducer';
import { locationMiddleware } from './locationMiddleware';
import { subjectMetaMiddleware } from './subjectMetaMiddleware';

export function configureStore(history: History): Store {
  const middlewares = [
    reduxThunk,
    routerMiddleware(history),
    locationMiddleware,
    subjectMetaMiddleware,
  ];

  const store = createStore(
    createRootReducer(history),
    {} as RootState,
    compose(applyMiddleware(...middlewares))
  );

  return store;
}
