import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  max-width: 400px;

  p {
    font-size: 1.25em;
    font-weight: 200;
    color: white;
  }

  h1 {
    font-family: 'Gotham-Medium', sans-serif;
    font-size: 2em;
    color: white;
    margin-bottom: 0.3em;
  }
`;

export const LinkButton = styled.button`
  border: none;
  display: inline-flex;
  color: ${({ theme }): string => theme.accentColors.mint.primary};
  background-color: transparent;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  margin: 0.2em;

  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const Form = styled.form`
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  p {
    font-size: 1em;
  }
`;

export const Input = styled.input`
  filter: none;
  border: none;
  height: 24px;
  border-bottom: ${({ theme }) => `1px solid ${theme.admin.light}`};
  margin-bottom: 1em;
  background-color: transparent;
  color: white;

  &:focus {
    outline: none;
  }

  /* Chrome, Firefox, Opera, Safari 10.1+ */
  ::placeholder {
    color: ${({ theme }) => theme.admin.light};
    opacity: 1; /* Firefox */
  }

  /* Internet Explorer 10-11 */
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.admin.light};
  }

  /* Microsoft Edge */
  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.admin.light};
  }
`;

export const Label = styled.label`
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 1em;
  color: white;
  /* margin-bottom: 5px; */
`;
