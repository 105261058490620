import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { LinkButton } from '../AuthComponent.styled';
import ResetPasswordForm from './ResetPasswordForm';

/* eslint-disable-next-line */
export interface ResetPasswordProps extends RouteComponentProps {}

export const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const goBackToLogin = (): void => {
    props.history.replace('login');
  };
  return (
    <>
      <h1>Glemt passord</h1>
      <p>
        Vennligst fyll in eposten din, så sender vi en link for å endre
        passordet
      </p>
      <ResetPasswordForm />
      <LinkButton onClick={goBackToLogin}>{'<< Tilbake'}</LinkButton>
    </>
  );
};

export default ResetPassword;
