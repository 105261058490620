import React from 'react';
import ReactDOM from 'react-dom';

import { DimmedBackground, StyledContainer } from './modal.styled';

export interface ModalProps {
  height?: number;
  width?: number;
  onClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({ height, width, children, onClose }) => {
  return ReactDOM.createPortal(
    <DimmedBackground onClick={onClose}>
      <StyledContainer
        height={height}
        width={width}
        onClick={(event): void => event.stopPropagation()}
      >
        {children}
      </StyledContainer>
    </DimmedBackground>,
    // We know that this element exists, but getElementById returns HTMLElement | null.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('modal-root')!
  );
};

export default Modal;
