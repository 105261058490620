import styled from 'styled-components';

export const MenuWrapper = styled.div<{ dark?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.dark ? props.theme.primary[2] : props.theme.primary[1]};

  @media only screen and (min-width: 768px) {
    min-height: 100vh;
  }
`;
