/* eslint-disable react/no-danger */
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import marked from 'marked';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { InlineMath, BlockMath as TeXBlock } from 'react-katex';
// import ReactMarkdown from 'react-markdown'
// import TeX from '@matejmazur/react-katex';
import { MarkdownCell, BoxCell, Image } from './previewCells.styled';
import EquationCell from './equationCell';
import { useImageUrl } from '@mestr/firebase';

const applyInlineMath = (cellContent: string): string => {
  const newContent = cellContent
    .replace(/((\$\$)[^$$\n]+(\$\$))/g, (_, p1) =>
      ReactDOMServer.renderToStaticMarkup(
        <InlineMath>{p1.substring(2, p1.length - 2)}</InlineMath>
      )
    )
    .replace(/((\$)[^$\n]+(\$))/g, (_, p1) =>
      ReactDOMServer.renderToStaticMarkup(
        <InlineMath>{p1.substring(1, p1.length - 1)}</InlineMath>
      )
    );

  return newContent;
};



const Markdown: React.FC<{ content: string }> = ({ content }) => (
  <MarkdownCell>
    <span
      dangerouslySetInnerHTML={{
        __html: marked(applyInlineMath(content)),
      }}
    />
  </MarkdownCell>
);

const BlockMath: React.FC = ({ children }) => (
  <EquationCell>
    <TeXBlock>{children}</TeXBlock>
  </EquationCell>
);

const ImageCell: React.FC<{ imageId: string }> = ({ imageId }) => {
  const [imageUrl, loading, error] = useImageUrl(imageId);

  if (!imageId) {
    return null
  }

  if (imageUrl === undefined || loading) {
    return <p>loading image...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  return <Image src={imageUrl} />;
};

const renderBoxContent = (cellContent: string): JSX.Element[] => {
  const contentArray = cellContent.trimRight().split('$$$');
  if (contentArray[contentArray.length - 1] === ('')) {
    contentArray.pop()
  }
  let blockMathModuloIndex = 1
  if (cellContent.startsWith('$$$')) {
    blockMathModuloIndex = 0
  }

  return contentArray.map((currentValue, index) => {
    if (index % 2 === blockMathModuloIndex) {
      return <TeXBlock key={index}>{currentValue}</TeXBlock>
    } else {
      return <Markdown key={index} content={currentValue} />
    }
  })
};

const Box: React.FC<{ content: string }> = ({ content }) => {
  return (
    <BoxCell>
      {renderBoxContent(content)}
    </BoxCell>
  );
};

export { Markdown, BlockMath, ImageCell, Box };
