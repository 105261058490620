import React from 'react';
import { Route } from 'react-router-dom';
import { Wrapper } from './AuthComponent.styled';
import Login from './login/login';
import Signup from './signup/signup';
import ResetPassword from './reset-password/reset-password';
import Verify from './verify/verify';

export const AuthComponent: React.FC = () => {
  return (
    <Wrapper>
      <Route path="*/login" component={Login} />
      <Route path="*/signup" component={Signup} />
      <Route path="*/verify" component={Verify} />
      <Route path="*/reset-password" component={ResetPassword} />
    </Wrapper>
  );
};

export default AuthComponent;
