import { User } from '@mestr/firebase';
import { LOGGED_IN, LOGGED_OUT, AuthActionTypes } from './types';

export function logInUser(user: User): AuthActionTypes {
  return {
    type: LOGGED_IN,
    payload: { user },
  };
}

export function logOutCurrentUser(): AuthActionTypes {
  return {
    type: LOGGED_OUT,
  };
}
