import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

interface StyledNavProps {
  solid?: boolean;
  light?: boolean;
}

export const StyledNav = styled.nav<StyledNavProps>`
  height: 80px;
  background-color: ${({ theme, solid }) =>
    solid ? theme.white : '#00000000'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0em 4em;
  box-shadow: ${({ solid }) =>
    solid ? '0px 0px 10px rgba(0, 0, 0, 0.18)' : 'none'};
  z-index: 1;

  grid-area: navbar;

  color: ${({ theme, light }) => (light ? theme.adminWhite : theme.mestrBlack)};
  font-family: 'Gotham-Book', sans-serif;
`;

export const StyledAdminNav = styled(StyledNav) <StyledNavProps>`
  background-color: ${({ theme }) => theme.admin.primary};
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 200ms ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    opacity: 0.5;
    transition: opacity 50ms ease-in-out;
  }

  p {
    color: ${({ theme }) => theme.mestrBlack};
    font-size: 1.4em;
  }
`;

export const Logo = styled.img.attrs<{ light?: boolean }>(({ light }) => ({
  alt: 'Mestr logo',
  src: light ? '/assets/images/logo_light.svg' : '/assets/images/logo.png',
})) <{ light?: boolean }>`
  max-height: 40px;
`;

export const ProfileIcon = styled.img.attrs<{ light?: boolean }>(
  ({ light }) => ({
    alt: 'Profile icon',
    src: light
      ? '/assets/icons/profileIconLight.svg'
      : '/assets/icons/profileIcon.png',
  })
) <{ light?: boolean }>`
  width: 14px;
  height: 14px;
`;

export const IconButton = styled.button`
  height: 31px;
  width: 31px;
  margin-left: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SignOutIcon = styled(({ light, ...props }) => (
  <FontAwesomeIcon
    {...props}
    icon={faSignOutAlt}
  />
)) <{ light?: boolean }>`
  font-size: 1.1em;
  color: ${({ light, theme }) => light ? theme.adminWhite : theme.mestrBlack}
`;

export const SignOutButton = styled(IconButton)`
  transition: all 200ms ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    opacity: 0.5;
    transition: opacity 50ms ease-in-out;
  }
`;

export const UserSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
