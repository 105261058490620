import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Home } from '../Home'
import { MenuWrapper } from './MenuRouter.styled'
import Navbar from '../../components/Navbar';
import { Footer } from '../../components/Footer';
import { PrivateRoute, Spinner } from '@mestr/components';
const SubjectDashboard = lazy(() => import('../Dashboard/SubjectDashboard'));
const TopicDashboard = lazy(() => import('../Dashboard/TopicDashboard'));
const Profile = lazy(() => import('../Profile'));

const MenuRouter: React.FC = () => {
  return (
    <MenuWrapper>
      <Navbar />
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route
            path={[
              '/subject/:subjectId/topic/:topicId',
              '/subject/:subjectId/topic/:topicId/summary',
              '/subject/:subjectId/topic/:topicId/subtopic/:subtopicId',
            ]}
            component={TopicDashboard}
          />
          <Route
            path={['/subject/:subjectId', '/subject/:subjectId/summary']}
            component={SubjectDashboard}
          />
          <Route
            path={['/', '*/login', '*/signup', '*/verify', '*/reset-password']}
            exact
            component={Home}
          />
          <PrivateRoute path='/user' exact component={Profile} />
        </Switch>
        <Footer />
      </Suspense>
    </MenuWrapper>
  )
}

export default MenuRouter
