import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LinkButton } from '../AuthComponent.styled';
import LoginForm from './LoginForm';
import { uiConfig, authRef, User } from '@mestr/firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { ProjectRootState } from '@mestr/reducers';
import { useSelector } from 'react-redux';
import { removeFromPath } from '@mestr/route-utils';

/* eslint-disable-next-line */
export interface LoginProps extends RouteComponentProps {}

const Login: React.FC<LoginProps> = (props) => {
  const user = useSelector<ProjectRootState, User | null>(
    (state) => state.auth.user
  );

  useEffect(() => {
    if (user) {
      props.history.replace(removeFromPath('login', props.history));
    }
  }, [user, props.history]);

  const goToSignup = (): void => {
    props.history.replace('signup');
  };

  const goToResetPassword = (): void => {
    props.history.replace('reset-password');
  };

  return (
    <>
      <LoginForm />
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={authRef} />
      <LinkButton type="button" onClick={goToResetPassword}>
        Glemt passord?
      </LinkButton>
      <LinkButton onClick={goToSignup}>Opprett bruker</LinkButton>
    </>
  );
};

export default Login;
