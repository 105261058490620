import styled from 'styled-components';

export const WelcomeWrapper = styled.section`
  display: flex;
  margin-top: 55px; // Mobile navbar height
  height: calc(100vh - 55px);
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */

  @media only screen and (min-width: 768px) {
    margin-top: 0;
    flex-direction: row;
    max-height: calc(100vh - 80px);
  }

  background-color: ${({ theme }) => theme.primary[1]};
`;

export const WelcomeText = styled.div`

  padding: 6em 3em;

  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    padding: 9em;
    width: 40%;
  }

  h1 {
    color: ${({ theme }) => theme.mestrBlack};
    font-size: 3em;
    font-family: Gotham-medium;
  }

  p {
    padding: 1em 0em 2em 0em;
    color: ${({ theme }) => theme.mestrBlack};
    font-size: 1.2em;
    line-height: 1.2em;
  }

  button {
    width: 166px;
    height: 46.27px;
    border-radius: 40px;
    color: ${({ theme }) => theme.mestrBlack};
    font-size: 1.2em;
    font-family: Gotham-medium;
    font-weight: 700;
    box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.18);
    border: none;
    background-color: ${({ theme }) => theme.secondary[1]};
    transition: all 0.15s ease-in-out;
  }

  button:focus {
    outline: none;
    border: 2px solid #eee;
    box-shadow: 3px 3px 20px -2px rgba(0, 0, 0, 0.35);
  }

  button:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.secondary[2]};
  }

  button:active {
    transform: scale(0.95);
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const Figure = styled.img.attrs({
  src: './assets/images/em_lightbulb.png',
  alt: 'Figur'
})`
  //To be able to click on login which gets partially hidden
  pointer-events: none;
  height: 50%;
  object-fit: scale-down;
  -webkit-transform: translateY(8.5%);
  -moz-transform: translateY(8.5%);
  transform: translateY(8.5%);

@media only screen and (min-width: 768px) {
  height: 110%;
  width: 50%;
  align-self: flex-end;
}
`;
