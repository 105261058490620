import { useDownloadURL } from 'react-firebase-hooks/storage';
import { imageStorageRef } from './firebase';

export function useImageUrl(
  imageId?: string
): [string | undefined, boolean, Error | undefined] {
  return useDownloadURL(imageId ? imageStorageRef.child(imageId) : null);
}

export async function uploadImage(ref: string, file: File): Promise<void> {
  const imageRef = imageStorageRef.child(ref);
  await imageRef.put(file);
  return;
}

export async function removeImage(ref: string): Promise<void> {
  return await imageStorageRef.child(ref).delete();
}
