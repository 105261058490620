import React from 'react';
import { useHistory } from 'react-router-dom';

import { Subject, useSubjects } from '@mestr/firebase';

import { ErrorUI, Card } from '../../../components/SharedUI';

export const SubjectList: React.FC = () => {
  const history = useHistory();
  const [subjects, loading, error] = useSubjects();

  const handleClick = (subject: Subject): void => {
    history.push(`/subject/${subject.id}`);
  };

  return (
    <>
      {loading && (
        <Card disabled dark>
          <h3>Laster fag...</h3>
        </Card>
      )}
      {subjects &&
        subjects.map(
          (subject) =>
            subject.name !== 'test' && (
              <Card
                dark
                key={subject.id}
                onClick={(): void => handleClick(subject)}
              >
                <h3>
                  {subject.name} <i> – NTNU</i>
                </h3>
              </Card>
            )
        )}
      {!loading && (
        <Card disabled dark>
          <h3>Flere fag kommer snart</h3>
          <p>Følg med!</p>
        </Card>
      )}
      {error && <ErrorUI>{error}</ErrorUI>}
    </>
  );
};
