import React, { FormEvent, useState } from 'react';
import { Form, Input, Label } from '../AuthComponent.styled';
import { doResetPassword } from '@mestr/firebase';
import { SecondaryRoundedButton } from '@mestr/components';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ResetPasswordFormProps {}

const ResetPasswordForm = (props: ResetPasswordFormProps): JSX.Element => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    doResetPassword(email)
      .then(() => {
        setEmailSent(true);
        setError('');
      })
      .catch((error: Error) => {
        setError(error.message);
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Label htmlFor="email">E-post</Label>
      <Input
        name="email"
        type="email"
        value={email}
        onChange={(e): void => setEmail(e.target.value)}
      />
      <p style={{ color: 'red' }}>{error}</p>
      {emailSent ? (
        <p style={{ color: 'green', marginBottom: '1em' }}>
          E-post sendt. Sjekk innboksen din!
        </p>
      ) : (
        <SecondaryRoundedButton type="submit">Send</SecondaryRoundedButton>
      )}
    </Form>
  );
};

export default ResetPasswordForm;
