import styled from 'styled-components';

export const Card = styled.button<{
  dark?: boolean;
}>`
  flex: 1 1 0px;

  background-color: ${(props) => {
    if (props.dark) {
      return `${props.theme.primary[3]};`;
    }

    return `${props.theme.primary[2]};`;
  }};

  outline: none;
  border: none;

  box-sizing: border-box;
  display: inline-block;

  max-height: 100px;
  padding: 2em;
  margin: 0.25em 0;

  color: black;

  transition: all 0.15s;
  box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.1);

  &:hover:enabled {
    cursor: pointer;
    box-shadow: 1px 1px 7px -1px rgba(0, 0, 0, 0.1);
    transform: scaleY(1.01);
  }

  &:active:enabled {
    transform: scale(0.99);
    box-shadow: none;
  }

  h3,
  p {
    text-align: start;
  }

  h3 {
    font-family: 'Gotham-Medium', sans-serif;

    i {
      font-style: normal;
      font-family: system-ui, sans-serif;
      font-weight: 300;
    }
  }

  p {
    font-family: system-ui, sans-serif;
    font-weight: 300;
  }
`;
