import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface RotateProps {
  speed?: number;
}

export const Rotate = styled.div<RotateProps>`
  flex: 0;
  animation: ${rotate} ${(props) => (props.speed ? `${props.speed}s` : '0.5s')}
    linear infinite;
`;
