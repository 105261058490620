import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SignupForm from './SignupForm';
import { LinkButton } from '../AuthComponent.styled';

/* eslint-disable-next-line */
export interface SignupProps extends RouteComponentProps {}

export const Signup: React.FC<SignupProps> = (props) => {
  const goToLogin = (): void => {
    props.history.replace('login');
  };

  return (
    <>
      <SignupForm />
      <LinkButton onClick={goToLogin}>Logg inn</LinkButton>
    </>
  );
};

export default Signup;
