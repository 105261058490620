import styled from 'styled-components';

export const Cell = styled.div.attrs(() => ({
  className: 'cell',
}))`
  .katex-display > .katex {
    display: inline-block;
    white-space: nowrap;
    max-width: 100%;
    text-align: initial;
  }

  .katex {
    font: normal 1.21em KaTeX_Main, Times New Roman, serif;
    line-height: 1.5em;
    overflow-y: visible;
    white-space: normal;
    text-indent: 0;
  }

  font: 1rem system-ui;
  font-weight: 300;
  word-break: break-word;

  padding: 0.5em 0;
`;

export const MarkdownCell = styled(Cell).attrs((props) => ({
  className: `${props.className} cell-markdown`,
}))`
  h1 {
    font-family: 'Gotham-Medium', system-ui;
    font-size: 2rem;
    text-transform: uppercase;
  }

  h2 {
    font-family: 'Gotham-Medium', system-ui;
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 700;
  }

  h4 {
    font-weight: 500;
  }

  h5 {
    font-style: italic;
    font-weight: 400;
  }

  h6 {
    font-style: italic;
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

export const BoxCell = styled(Cell)`
  border: 2px solid lightgray;
  border-radius: 10px;
  padding: 1.5em;
`;

export const Image = styled.img.attrs<{ src: string }>(({ src }) => ({
  src,
}))`
  max-width: 100%;
  margin: 1.5em 0;
`;
