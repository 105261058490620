import firebase from './firebase';

/* AUTH TYPES */
export type UserCredential = firebase.auth.UserCredential;

/**
 * The user as represented by Firebase (contains a bunch of stuff we are not
 * interested in).
 */
export type FirebaseUser = firebase.User;

/**
 * The user as represented by us – should only contain the fields that we find
 * interesting.
 */

export type CustomClaims = {
  role?: 'user' | 'admin' | 'moderator';
  subjects?: [string];
};

export interface User {
  uid: string;
  emailVerified: boolean;
  displayName: string | null;
  email: string | null;
}

/* FIRESTORE TYPES */
export type DocumentData = firebase.firestore.DocumentData;
export type Timestamp = firebase.firestore.Timestamp;
export type Reference = firebase.firestore.DocumentReference;

export interface Subject {
  id: string;
  name: string;
}

export interface Topic {
  id: string;
  name: string;
  index: number;
}

export interface Subtopic {
  id: string;
  name: string;
  index: number;
  compendium: CelledDocument;
}

export interface Exercise {
  id: string;
  name: string;
  q: CelledDocument;
  a: CelledDocument;
  subjectId: string;
  topicId: string;
  subtopicId: string;
  index: number;
}

/**
 * Representation of one exercise attempt by a user.
 */
export interface Attempt {
  masteryPercentage: number;
  numberOfHints: number;
  timestamp: Timestamp;
}

/**
 * The representation of an exercise in a user's exercise queue.
 */
export interface ExerciseQueueItem {
  attempts: Attempt[];
  due: Timestamp;
  lastInterval: number;
  ref: Reference;
  skippedUntil: Timestamp;
  subtopicId: string;
  topicId: string;
}

export enum CellType {
  TEXT = 'text',
  MATH = 'math',
  BOX = 'box',
  IMAGE = 'image',
  VIDEO = 'video',
}

export interface Cell {
  type: string;
  value: string;
}

export interface TextCell extends Cell {
  type: CellType.TEXT;
}

export interface MathCell extends Cell {
  type: CellType.MATH;
}

export interface BoxCell extends Cell {
  type: CellType.BOX;
}

export interface ImageCell extends Cell {
  type: CellType.IMAGE;
}

export interface VideoCell extends Cell {
  type: CellType.VIDEO;
}

export interface CelledDocument {
  cellOrder: string[];
  cells: {
    [key: string]: Cell;
  };
}
export interface ExerciseMeta {
  score: number;
}

export interface SubtopicMeta {
  score: number;
  exercises: {
    [exerciseId: string]: ExerciseMeta;
  };
}

export interface TopicMeta {
  score: number;
  subtopics: {
    [subtopicId: string]: SubtopicMeta;
  };
}

export interface SubjectMeta {
  score: number;
  topics: {
    [topicId: string]: TopicMeta;
  };
}
