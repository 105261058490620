import styled from 'styled-components';

export const Anchor = styled.div`
  position: relative;
`;

export const Menu = styled.ul`
  position: absolute;
  top: 1.5em;
  right: 0;
  width: 180px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.primary[2]};
  border-radius: 5px;

  li {
    display: flex;
    height: 40px;
    flex-direction: row;
    align-items: center;
    padding: 0.75em 2em;
    border-bottom: 1px solid ${({ theme }) => theme.primary[5]};

    :last-child {
      border-bottom: none;
    }

    :hover {
      text-decoration: underline;
      transition: all 200ms ease-in-out;
      cursor: pointer;
    }
  }
`;
