import { Middleware } from 'redux';
import { fetchSubjectMeta } from '@mestr/firebase';
import {
  LOGGED_IN,
  LOGGED_OUT,
  LogInAction,
  AuthState,
  SUBJECT_SELECTED,
  SelectSubjectAction,
  SelectionState,
} from '@mestr/reducers';
import { setSubjectMeta } from './userStats';

/**
 * There are two scenarios in which we want to (re-)fetch the user's subjectMeta
 * document:
 * 1. When a user logs in and a subject is already selected
 * 2. When a subject gets selected and a user is already logged in
 *
 * When the user logs out or the subject selected is null (meaning subject was
 * unselected), we set the subjectmeta to null.
 */
export const subjectMetaMiddleware: Middleware = (store) => (next) => async (
  action
): Promise<void> => {
  const { subject }: SelectionState = store.getState().selection;
  const { user }: AuthState = store.getState().auth;

  if (action.type === SUBJECT_SELECTED) {
    const selectSubjectAction = action as SelectSubjectAction;
    const selectedSubject = selectSubjectAction.payload.subject;

    if (user !== null && selectedSubject !== null) {
      const subjectMeta = await fetchSubjectMeta(user.uid, selectedSubject.id);

      store.dispatch(setSubjectMeta(subjectMeta));
    } else {
      store.dispatch(setSubjectMeta(null));
    }
  }

  if (action.type === LOGGED_IN) {
    const logInAction = action as LogInAction;

    if (subject !== null) {
      const subjectMeta = await fetchSubjectMeta(
        logInAction.payload.user.uid,
        subject.id
      );

      store.dispatch(setSubjectMeta(subjectMeta));
    }
  }

  if (action.type === LOGGED_OUT) {
    store.dispatch(setSubjectMeta(null));
  }

  next(action);
};
