import styled from 'styled-components';

export const SubjectsWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: calc(8em + 150px); // Height of footer;

  @media only screen and (min-width: 768px) {
    min-height: 100vh;
    grid-template-columns: 1fr 1fr;
  }

  background-color: ${({ theme }) => theme.primary[2]};
`;

export const Description = styled.div`

  display: flex;
  align-items: center;

  div {
    padding: 5em 2em;
    @media only screen and (min-width: 768px) {
      padding: 8em;
    }
  }

  h1 {
    color: ${({ theme }) => theme.mestrBlack};
    font-size: 2.6em;
    font-family: Gotham-medium;
  }

  p {
    padding: 1em 0em;
    color: ${({ theme }) => theme.mestrBlack};
    font-size: 1.4em;
    line-height: 1.2em;
  }
`;

export const SubjectListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    padding: 0 20% 0 4em;
  }

`;
